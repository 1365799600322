import { Button, ButtonGroup, Checkbox, Form, Modal, SkeletonBodyText, TextField } from '@shopify/polaris';
import { StyledEditModal, StyledSearchAndReplaceWords } from './styled';
import { AccountPlan, CRUD } from '@/constants/enum';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  useCreateBlacklistWordPairMutation,
  useDeleteBlacklistWordPairMutation,
  useGetBlacklistingQuery,
  useUpdateBlacklistWordPairMutation,
} from '@/redux/api/api.caller';
import { useForm, useField, notEmpty, lengthLessThan } from '@shopify/react-form';
import commonSlice from '@/redux/features/common.slice';
import { getErrorFromAPI, handleToastMutation } from '@/helpers/toast';
import { DeleteMinor, EditMinor, PlusMinor } from '@shopify/polaris-icons';
import { accountSelector, christmasBannerSelector } from '@/redux/features/plan.slice';
import { CustomCard, ErrorBanner } from '@/components';
import { isLockFeature } from '@/helpers';

interface ISearchAndReplaceWordsModal {
  open: boolean;
  type: CRUD;
  initValue: {
    id: number;
    isRegex: boolean;
    findText: string;
    replaceText: string;
  };
}

const SearchAndReplaceWords = () => {
  const [modal, setModal] = useState<ISearchAndReplaceWordsModal>({
    open: false,
    type: CRUD.Read,
    initValue: { id: 0, isRegex: false, findText: '', replaceText: '' },
  });
  const [apiError, setApiError] = useState<{ create: string; delete: string }>({ create: '', delete: '' });
  const dispatch = useDispatch();
  const plan = useSelector(accountSelector);
  const { formattedEndDate } = useSelector(christmasBannerSelector);
  const isActiveChristmasFeature = new Date() < new Date(formattedEndDate);
  const isLockedFeature =
    isLockFeature([AccountPlan.Starter, AccountPlan.Basic]) && !plan.isOldPaidUser && !isActiveChristmasFeature;

  const { data, isLoading } = useGetBlacklistingQuery(undefined, { skip: isLockedFeature });
  const [createWordPair, createdWordPairData] = useCreateBlacklistWordPairMutation();
  const [updateWordPair, updatedWordPairData] = useUpdateBlacklistWordPairMutation();
  const [deleteWordPair, deletedWordPairData] = useDeleteBlacklistWordPairMutation();

  const checkEnterAllSpace = (value: string) => {
    if (value.trim().length === 0) {
      return 'Field is required';
    }
  };

  const { fields, submit } = useForm({
    fields: {
      isRegex: useField({
        value: modal.initValue.isRegex,
        validates: [],
      }),
      findText: useField({
        value: modal.initValue.findText,
        validates: [checkEnterAllSpace, notEmpty('Field is required'), lengthLessThan(100, 'Maximum length is 100 characters')],
      }),
      replaceText: useField({
        value: modal.initValue.replaceText,
        validates: [lengthLessThan(100, 'Maximum length is 100 characters')],
      }),
    },
    onSubmit: async (fieldValues) => {
      if (modal.type === CRUD.Create) {
        createWordPair(fieldValues).then((data) => {
          if ('data' in data && data.data && data.data.state === 1) {
            dispatch(commonSlice.actions.handleToast(handleToastMutation(data)));
            handleCloseModal();
          } else {
            setApiError({
              ...apiError,
              create: getErrorFromAPI(data),
            });
          }
        });
      }
      if (modal.type === CRUD.Update) {
        updateWordPair({ id: modal.initValue.id, ...fieldValues }).then((data) => {
          if ('data' in data && data.data && data.data.state === 1) {
            dispatch(commonSlice.actions.handleToast(handleToastMutation(data)));
            handleCloseModal();
          } else {
            setApiError({
              ...apiError,
              create: getErrorFromAPI(data),
            });
          }
        });
      }
      return { status: 'success' };
    },
  });

  const handleCloseModal = () => {
    setApiError({ create: '', delete: '' });
    setModal((prevValue) => ({
      ...prevValue,
      open: false,
      type: CRUD.Read,
      initValue: { id: 0, isRegex: false, findText: '', replaceText: '' },
    }));
  };

  const handleAddNewReplaceWord = () => {
    setModal((prevValue) => ({ ...prevValue, open: true, type: CRUD.Create }));
  };

  const handleEditReplaceWord = (id: number) => {
    if (data && data.data.wordsReplacePairs) {
      const initValue = data.data.wordsReplacePairs.find((item) => item.id === id) || {
        id: 0,
        isRegex: false,
        findText: '',
        replaceText: '',
      };
      setModal((prevValue) => ({ ...prevValue, open: true, type: CRUD.Update, initValue }));
    }
  };

  const handleDeleteReplaceWord = (id: number) => {
    setModal((prevValue) => ({
      ...prevValue,
      open: true,
      type: CRUD.Delete,
      initValue: { id, isRegex: false, findText: '', replaceText: '' },
    }));
  };

  const handleRemoveKeywordBlackList = () => {
    deleteWordPair({ id: modal.initValue.id }).then((data) => {
      if ('data' in data && data.data && data.data.state === 1) {
        dispatch(commonSlice.actions.handleToast(handleToastMutation(data)));
        handleCloseModal();
        return;
      }
      setApiError({
        ...apiError,
        delete: getErrorFromAPI(data),
      });
    });
  };

  const renderSearchAndReplaceWordsContent = () => {
    if (isLoading) {
      return <SkeletonBodyText lines={5} />;
    }
    if (data && data.data.wordsReplacePairs.length > 0) {
      return data.data.wordsReplacePairs.map(({ findText, replaceText, id }) => (
        <div key={id} className="replace-text-item">
          <p>
            {findText}
            <span>with</span>
            {replaceText}
          </p>
          <ButtonGroup>
            <Button onClick={() => handleEditReplaceWord(id)} icon={EditMinor} />
            <Button onClick={() => handleDeleteReplaceWord(id)} icon={DeleteMinor} />
          </ButtonGroup>
        </div>
      ));
    }
    return <p className="no-replace-text">You haven't created any search and replace words yet</p>;
  };

  return (
    <StyledSearchAndReplaceWords>
      <CustomCard
        className="mt-16"
        title="Search and replace words"
        actionGroup={[
          {
            content: 'Add new line',
            variant: 'plain',
            onAction: handleAddNewReplaceWord,
            icon: PlusMinor,
            disabled: isLockedFeature,
          },
        ]}
      >
        {renderSearchAndReplaceWordsContent()}
      </CustomCard>

      {1
        ? (() => {
            return <span>{}</span>;
          })()
        : ''}
      <Form onSubmit={submit}>
        <Modal
          open={modal.open && (modal.type === CRUD.Update || modal.type === CRUD.Create)}
          onClose={handleCloseModal}
          title={modal.type === CRUD.Create ? 'Find & replace' : 'Edit Search and Replace'}
          primaryAction={{
            content: 'Save',
            disabled: !fields.isRegex.dirty && !fields.findText.dirty && !fields.replaceText.dirty,
            onAction: submit,
            loading: createdWordPairData.isLoading || updatedWordPairData.isLoading,
          }}
          secondaryActions={[
            {
              content: 'Cancel',
              onAction: handleCloseModal,
            },
          ]}
        >
          <Modal.Section>
            {apiError.create && (
              <div className="mb-8">
                <ErrorBanner isVisible>{apiError.create}</ErrorBanner>
              </div>
            )}
            <StyledEditModal>
              <Checkbox
                label="RegEx"
                checked={fields.isRegex.value}
                onChange={fields.isRegex.onChange}
                onBlur={fields.isRegex.onBlur}
              />
              <div className="textfield">
                <TextField label="Find words" autoComplete="off" {...fields.findText} />
              </div>
              <div className="textfield">
                <TextField label="Replace words" autoComplete="off" {...fields.replaceText} />
              </div>
            </StyledEditModal>
          </Modal.Section>
        </Modal>
      </Form>

      <Modal
        open={modal.open && modal.type === CRUD.Delete}
        onClose={handleCloseModal}
        title="Remove this keyword"
        primaryAction={{
          content: 'Delete',
          onAction: handleRemoveKeywordBlackList,
          loading: deletedWordPairData.isLoading,
          destructive: true,
        }}
        secondaryActions={[
          {
            content: 'Cancel',
            onAction: handleCloseModal,
          },
        ]}
      >
        <Modal.Section>
          {apiError.delete && <ErrorBanner isVisible>{apiError.delete}</ErrorBanner>}
          Are you certain you want to remove this keyword?
        </Modal.Section>
      </Modal>
    </StyledSearchAndReplaceWords>
  );
};

export default SearchAndReplaceWords;
