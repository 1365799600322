import { ShipmentStatus } from '@/constants/shipment';
import { camelToRegularText, transformOptions } from '@/helpers';
import shipmentSlice, { statusModalSelector } from '@/redux/features/shipment.slice';
import { BlockStack, Button, Checkbox, Divider, FormLayout, Modal, Select, SelectOption, TextField } from '@shopify/polaris';
import { useDispatch, useSelector } from 'react-redux';
import { StyledStatusModal } from './styled';
import { DeleteMinor } from '@shopify/polaris-icons';
import dayjs from 'dayjs';
import { ITrackingDetail, IUnsupportedTrackingPayload } from '@/types/shipment';
import { useSetUnSupportedTrackingMutation } from '@/redux/api/api.caller';
import commonSlice from '@/redux/features/common.slice';
import { getErrorFromAPI, handleToastMutation } from '@/helpers/toast';
import { useState } from 'react';
import { ErrorBanner } from '@/components';

const StatusModal = () => {
  const [apiError, setApiError] = useState<string>('');
  const dispatch = useDispatch();
  const statusModal = useSelector(statusModalSelector);

  const [setUpSupportedTracking, { isLoading }] = useSetUnSupportedTrackingMutation();

  const trackingStatusOptions = transformOptions(ShipmentStatus)
    .filter(({ value }) => value !== ShipmentStatus.All && value !== ShipmentStatus.OverQuota)
    .map((item) => ({
      ...item,
      label: camelToRegularText(item.value),
    })) as SelectOption[];

  const handleCloseModal = () => {
    setApiError('');
    dispatch(
      shipmentSlice.actions.handleChangeStatusModal({
        ...statusModal,
        open: false,
        orderId: '',
        trackingNumber: '',
        history: [],
      }),
    );
  };

  const handleChange = (value: string | boolean, key: string, index?: number) => {
    setApiError('');
    if (index !== undefined) {
      const updatedHistory = statusModal.history.map((item, idx) => {
        if (index === idx) {
          return { ...item, [key]: value };
        }
        return item;
      });
      return dispatch(shipmentSlice.actions.handleChangeStatusModal({ ...statusModal, history: updatedHistory }));
    }
    dispatch(shipmentSlice.actions.handleChangeStatusModal({ ...statusModal, [key]: value }));
  };

  const handleAddNewTrackingHistory = () => {
    const newHistory: ITrackingDetail[] = [
      ...statusModal.history,
      { date_time: dayjs().format('YYYY-MM-DD HH:mm'), message: '' },
    ];
    setApiError('');
    return dispatch(shipmentSlice.actions.handleChangeStatusModal({ ...statusModal, history: newHistory }));
  };

  const handleDeleteTrackingHistory = (index: number) => {
    const deletedHistory = statusModal.history.filter((_, idx) => idx !== index);
    setApiError('');
    return dispatch(shipmentSlice.actions.handleChangeStatusModal({ ...statusModal, history: deletedHistory }));
  };

  const handleSetUpSupportedTracking = () => {
    const payload: IUnsupportedTrackingPayload = {
      orderId: statusModal.orderId,
      trackingSummary: statusModal.status,
      trackingNumber: statusModal.trackingNumber,
      trackingDetail: statusModal.history,
      isSend: statusModal.isSend,
    };
    setUpSupportedTracking(payload).then((data) => {
      if ('data' in data && data.data && data.data.state === 1) {
        handleCloseModal();
        dispatch(commonSlice.actions.handleToast(handleToastMutation(data)));
        if (apiError) {
          setApiError('');
        }
        return;
      }
      setApiError(getErrorFromAPI(data));
    });
  };

  return (
    <Modal
      open={statusModal.open}
      onClose={handleCloseModal}
      title="Manual edit tracking info for this order"
      primaryAction={{
        content: 'Update tracking',
        onAction: handleSetUpSupportedTracking,
        loading: isLoading,
      }}
      footer={<Checkbox id="isSend" label="Send email to customers" checked={statusModal.isSend} onChange={handleChange} />}
    >
      <Modal.Section>
        <BlockStack gap="200">
          <ErrorBanner isVisible={!!apiError}>{apiError}</ErrorBanner>
          <StyledStatusModal>
            <Select
              id="status"
              label="Tracking status"
              options={trackingStatusOptions}
              value={statusModal.status}
              onChange={handleChange}
            />
            <div className="tracking-history">
              Tracking history
              <Button onClick={handleAddNewTrackingHistory}>Add Tracking History</Button>
            </div>
            <Divider />
            {statusModal.history.length > 0 ? (
              [...statusModal.history].reverse().map((history, index) => {
                const reverseIndex = statusModal.history.length - index - 1;
                return (
                  <div className="tracking-history-item" key={index}>
                    <FormLayout>
                      <FormLayout.Group>
                        <div>
                          <input
                            id="PolarisTextField7"
                            placeholder="Date record"
                            className="Polaris-TextField__Input custom-date-picker"
                            min="2021-06-10"
                            max="9999-01-01"
                            type="date"
                            aria-labelledby="PolarisTextField7Label"
                            aria-invalid="false"
                            inputMode="numeric"
                            value={dayjs(history.date_time).format('YYYY-MM-DD')}
                            onChange={(e) =>
                              handleChange(`${e.target.value} ${history.date_time.split(' ')[1]}`, 'date_time', reverseIndex)
                            }
                            onKeyDown={(event) => {
                              event.preventDefault();
                            }}
                          />
                        </div>

                        <TextField
                          id="date_time"
                          type="time"
                          placeholder="Time record"
                          label=""
                          labelHidden
                          autoComplete="off"
                          value={dayjs(history.date_time).format('HH:mm')}
                          onChange={(value, id) => handleChange(`${history.date_time.split(' ')[0]} ${value}`, id, reverseIndex)}
                        />
                      </FormLayout.Group>
                      <TextField
                        id="message"
                        label=""
                        labelHidden
                        placeholder="Tracking message"
                        multiline={3}
                        autoComplete="off"
                        value={history.message}
                        maxLength={255}
                        showCharacterCount
                        onChange={(value, id) => handleChange(value, id, reverseIndex)}
                      />
                    </FormLayout>
                    <Button icon={DeleteMinor} onClick={() => handleDeleteTrackingHistory(reverseIndex)} />
                  </div>
                );
              })
            ) : (
              <div className="no-data">No tracking history recorded</div>
            )}
          </StyledStatusModal>
        </BlockStack>
      </Modal.Section>
    </Modal>
  );
};

export default StatusModal;
