import { AppProvider } from '@shopify/polaris';
import { NavMenu } from '@shopify/app-bridge-react';
import { getSessionToken } from '@shopify/app-bridge-utils';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import './App.css';
import { NavigationLink } from './components';
import { Environment, LocalStorageKey, PATH, embeddedNavLinksWithoutReview } from './constants';
import { CONSTANT, convertQueryStringToObject, setLocalStorageItem } from './helpers';
import authSlice, { sessionTokenSelector, tokenSelector } from './redux/features/auth.slice';
import translations from '@shopify/polaris/locales/en.json';
import AppRoutes from './routes';
import useScript from './hooks/useScript';
import { createApp } from '@shopify/app-bridge';
import { useCheckStoreReturnsQuery } from './redux/api/api.caller';

function App() {
  useScript();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { search } = location;
  const { token } = useSelector(tokenSelector);
  const { token: sessionToken } = useSelector(sessionTokenSelector);
  const searchToObj = convertQueryStringToObject(search);
  const configApp = {
    apiKey: process.env.REACT_APP_API_KEY || '',
    host:
      new URLSearchParams(search).get('host') ||
      window.btoa('admin.shopify.com/store/' + token?.shop.split('.')[0]).replaceAll('=', ''),
    forceRedirect: process.env.REACT_APP_ENV === Environment.Production,
  };
  const { data: checkStoreData } = useCheckStoreReturnsQuery(
    {
      urlParams: token?.urlParams || '',
    },
    {
      skip: !token?.urlParams,
    },
  );

  // AUTHENTICATION
  useEffect(() => {
    if (searchToObj?.shop && searchToObj.shop !== token?.shop) {
      setLocalStorageItem(LocalStorageKey.OMEGA_SHOP, searchToObj.shop);
      setLocalStorageItem(LocalStorageKey.LOGIN_QUERY_STRING, search);
      dispatch(
        authSlice.actions.handleAuth({
          shop: searchToObj.shop,
          token: { ...searchToObj, urlParams: JSON.stringify(searchToObj) },
          isAuth: true,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleNavigateStoreReturn = () => {
    if (checkStoreData) {
      const { url } = checkStoreData;
      if (url) {
        window.open(url, '_blank');
      }
    }
    navigate(PATH.DASHBOARD);
  };

  useEffect(() => {
    if (CONSTANT.isEmbedded && !sessionToken) {
      const getToken = async () => {
        try {
          const app = createApp(configApp);
          const sessionToken = await getSessionToken(app);
          dispatch(authSlice.actions.handleGetSessionToken({ token: sessionToken, expiredTime: Date.now() + 60 * 1000 }));
        } catch (error) {
          console.log('Error when get session token', error);
        }
      };
      getToken();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AppProvider i18n={translations} linkComponent={NavigationLink}>
      {process.env.REACT_APP_ENV !== Environment.Test && CONSTANT.isEmbedded ? (
        <>
          <NavMenu>
            {embeddedNavLinksWithoutReview.map(({ label, destination }) => {
              return (
                <NavLink
                  id={destination}
                  key={destination}
                  to={destination}
                  onClick={() => {
                    if (label === 'Returns') {
                      handleNavigateStoreReturn();
                    } else {
                      navigate(destination);
                    }
                  }}
                >
                  {label}
                </NavLink>
              );
            })}
          </NavMenu>
          <AppRoutes />
        </>
      ) : (
        <AppRoutes />
      )}
    </AppProvider>
  );
}

export default App;
