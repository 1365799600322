import { ColorPicker, CustomCard, ErrorBanner } from '@/components';
import { OrderLookupStyled } from './styled';
import {
  Checkbox,
  InlineGrid,
  Icon,
  RadioButton,
  RangeSlider,
  Select,
  SkeletonBodyText,
  Text,
  TextField,
  Tooltip,
  Divider,
  ButtonProps,
} from '@shopify/polaris';
import { useDispatch, useSelector } from 'react-redux';
import settingsSlice, { orderLookupSelector } from '@/redux/features/settings.slice';
import { WidgetTrackingForm } from '@/constants/enum';
import { orderLookupOptions } from '@/constants';
import { CircleInformationMajor } from '@shopify/polaris-icons';
import { useGetOrderLookupQuery, useUpdateOrderLookupMutation } from '@/redux/api/api.caller';
import { useEffect, useState } from 'react';
import commonSlice from '@/redux/features/common.slice';
import { getErrorFromAPI, handleToastMutation } from '@/helpers/toast';
import { orderLookupCode, radioBtnConfig } from './config';
import { CopyIcon } from '@/icons';

const ShowTrackingLabel = () => {
  return (
    <span className="show-tracking-result">
      <Text as="p" variant="bodySm">
        Show tracking result in the Widget
      </Text>
      <div className="ml-8">
        <Tooltip content="This option allows short summary of tracking result is shown directly in the widget">
          <Icon source={CircleInformationMajor} />
        </Tooltip>
      </div>
    </span>
  );
};

const OrderLookup = () => {
  const getOrderLookup = useGetOrderLookupQuery();
  const [state, setState] = useState<any>({});
  const [apiError, setApiError] = useState<string>('');
  const [updateOrderLookup, updateOrderLookupStatus] = useUpdateOrderLookupMutation();
  const dispatch = useDispatch();
  const orderLookup = useSelector(orderLookupSelector);

  useEffect(() => {
    if (getOrderLookup.data) {
      const { data } = getOrderLookup.data;
      const initState = {
        alignment: data.alignment,
        btnBackgroundColor: data.buttonStyle.background,
        btnLabel: data.buttonStyle.label,
        btnRadius: Number(data.buttonStyle.shape.slice(0, -2)),
        btnTextColor: data.buttonStyle.text,
        emailRequired: data.trackingForm.isRequireEmail,
        isShowTrackingResult: data.isShowResultInWidget,
        widgeTitle: data.widgetTitle,
        widgetSize: data.widgetSize,
        widgetTracking: data.trackingForm.typeMethodTracking,
      };
      setState(initState);
      dispatch(settingsSlice.actions.handleOrderLookup(initState));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getOrderLookup.data]);

  const handleChange = (key: keyof typeof orderLookup) => (value: string | [number, number] | number | boolean) => {
    setApiError('');
    dispatch(
      settingsSlice.actions.handleOrderLookup({
        ...orderLookup,
        [key]: value,
      }),
    );
  };

  const handleChangeColor = (key: keyof typeof orderLookup) => (e: any) => {
    setApiError('');
    const { value } = e.target;
    dispatch(
      settingsSlice.actions.handleOrderLookup({
        ...orderLookup,
        [key]: value,
      }),
    );
  };

  const handleUpdateOrderLookup = () => {
    updateOrderLookup({
      alignment: orderLookup.alignment,
      buttonStyle: {
        background: orderLookup.btnBackgroundColor,
        label: orderLookup.btnLabel,
        shape: `${orderLookup.btnRadius}px`,
        text: orderLookup.btnTextColor,
      },
      isShowResultInWidget: orderLookup.isShowTrackingResult,
      trackingForm: {
        isRequireEmail: orderLookup.emailRequired,
        typeMethodTracking: orderLookup.widgetTracking,
      },
      widgetSize: orderLookup.widgetSize,
      widgetTitle: orderLookup.widgeTitle,
    }).then((res) => {
      if ('data' in res && res.data && res.data.state === 1) {
        dispatch(commonSlice.actions.handleToast(handleToastMutation(res)));
        setState(orderLookup);
        if (apiError) {
          setApiError('');
        }
        return;
      }
      setApiError(getErrorFromAPI(res));
    });
  };

  const actionGroup = [
    {
      content: 'Save',
      variant: 'primary' as ButtonProps['variant'],
      onAction: handleUpdateOrderLookup,
      primary: true,
      loading: updateOrderLookupStatus.isLoading,
      disabled: Object.entries(state).toString() === Object.entries(orderLookup).toString(),
    },
  ];

  return (
    <OrderLookupStyled>
      <CustomCard title="Order Lookup Widget" actionGroup={actionGroup}>
        {getOrderLookup.isLoading ? (
          <SkeletonBodyText lines={30} />
        ) : (
          <>
            <div className="mb-8">
              <ErrorBanner isVisible={!!apiError}>{apiError}</ErrorBanner>
            </div>
            <div>
              <Text as="h6" variant="headingSm">
                Widget setting
              </Text>
              <TextField
                label="Widget title"
                value={orderLookup.widgeTitle}
                onChange={handleChange('widgeTitle')}
                autoComplete="off"
              />
              <div style={{ marginBlock: 16 }}>
                <Divider />
              </div>
              <div className="mb-8">
                <Text as="h6" variant="headingSm">
                  Widget tracking form
                </Text>
              </div>
              {radioBtnConfig.map((item) => {
                return (
                  <div key={item.value} className="mt-4">
                    <RadioButton
                      key={item.value}
                      label={item.label}
                      checked={orderLookup.widgetTracking === item.value}
                      id={item.value}
                      name={item.value}
                      onChange={() => handleChange('widgetTracking')(item.value)}
                    />
                  </div>
                );
              })}
              {orderLookup.widgetTracking !== WidgetTrackingForm.TrackingNumber && (
                <div className="mt-8">
                  <Checkbox
                    label="Require email when tracking with Order ID"
                    checked={orderLookup.emailRequired}
                    onChange={handleChange('emailRequired')}
                  />
                </div>
              )}
              <div style={{ marginBlock: 8 }}>
                <Divider />
              </div>
              <Text as="h6" variant="headingSm">
                Button style
              </Text>
              <TextField label="Label" value={orderLookup.btnLabel} onChange={handleChange('btnLabel')} autoComplete="off" />
              <div className="mt-8">
                <InlineGrid columns={{ xs: 1, sm: 2, md: 2, lg: 2, xl: 2 }} gap="200">
                  <div>
                    <Text as="p" variant="bodySm">
                      Background Color
                    </Text>
                    <ColorPicker
                      bgColor={orderLookup.btnBackgroundColor}
                      value={orderLookup.btnBackgroundColor}
                      onChange={handleChangeColor('btnBackgroundColor')}
                    />
                  </div>
                  <div>
                    <Text as="p" variant="bodySm">
                      Text Color
                    </Text>
                    <ColorPicker
                      bgColor={orderLookup.btnTextColor}
                      value={orderLookup.btnTextColor}
                      onChange={handleChangeColor('btnTextColor')}
                    />
                  </div>
                </InlineGrid>
              </div>

              <RangeSlider
                max={25}
                min={0}
                label="Button Shape"
                value={orderLookup.btnRadius}
                onChange={handleChange('btnRadius')}
                output
                suffix={`${orderLookup.btnRadius}px`}
              />
              <InlineGrid columns={{ xs: 1, sm: 2, md: 2, lg: 2, xl: 2 }} gap="200">
                <Select
                  label="Widget size"
                  options={orderLookupOptions.size}
                  onChange={handleChange('widgetSize')}
                  value={orderLookup.widgetSize}
                />
                <Select
                  label="Alignment"
                  options={orderLookupOptions.alignment}
                  onChange={handleChange('alignment')}
                  value={orderLookup.alignment}
                />
              </InlineGrid>
              <div className="mt-8">
                <Checkbox
                  label={<ShowTrackingLabel />}
                  checked={orderLookup.isShowTrackingResult}
                  onChange={handleChange('isShowTrackingResult')}
                />
              </div>
              <div style={{ marginBlock: 8 }}>
                <Divider />
              </div>
              <Text as="h6" variant="headingSm">
                Get your code
              </Text>
              <div className="code-container">
                <div className="get-code-container">
                  {orderLookupCode}
                  <CopyIcon copiedText={orderLookupCode} />
                </div>
              </div>
            </div>
          </>
        )}
      </CustomCard>
    </OrderLookupStyled>
  );
};

export default OrderLookup;
