import Switch from '@/components/Switch';
import commonSlice from '@/redux/features/common.slice';
import { Badge, Button, Card, IndexTable, Modal, Tabs, Text, Tooltip } from '@shopify/polaris';
import { ViewMinor } from '@shopify/polaris-icons';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { statusConfig, tabs } from './config';
import { OrderStatusStyled } from './styled';
import { useGetSmsOrderStatusQuery, useUpdateSmsOrderStatusMutation } from '@/redux/api/api.caller';
import { AccountPlan, Receiver } from '@/constants/enum';
import { getErrorFromAPI, handleToastMutation } from '@/helpers/toast';
import { camelToSnakeCase, isLockFeature } from '@/helpers';
import smsSlice, { orderStatusTabSelectedSelector } from '@/redux/features/sms.slice';
import { CustomTable, ErrorBanner } from '@/components';

const SMSTemplates = () => {
  const [apiError, setApiError] = useState<string>('');
  const isLock = isLockFeature([AccountPlan.Starter]);
  const getSmsOrderStatus = useGetSmsOrderStatusQuery(undefined, { skip: isLock });
  const [updateSms, updateSmsStatus] = useUpdateSmsOrderStatusMutation();
  const [isOpenModalCredit, setIsOpenModalCredit] = useState(false);
  const [modalSetting, setModalSetting] = useState({
    isOpenModal: false,
    status: 'Pending',
  });
  const [state, setState] = useState<{ customer: Array<string>; merchant: Array<string> }>({
    customer: [],
    merchant: [],
  });
  const [status, setStatus] = useState<{ customer: string; merchant: string }>({ customer: '', merchant: '' });
  const dispatch = useDispatch();
  const tabSelected = useSelector(orderStatusTabSelectedSelector);

  useEffect(() => {
    if (getSmsOrderStatus.data) {
      const listActive = {
        customer: Object.entries(getSmsOrderStatus.data.data.customer)
          .filter(([_, value]) => value)
          .map(([key]) => {
            return key;
          }),
        merchant: Object.entries(getSmsOrderStatus.data.data.merchant)
          .filter(([_, value]) => value)
          .map(([key]) => {
            return key;
          }),
      };
      setState(listActive);
    }
  }, [getSmsOrderStatus.data]);

  const handleTabChange = (tab: number) => {
    setApiError('');
    dispatch(smsSlice.actions.handleOrderStatusTabSelected(tab));
  };
  const navigateSMSCredit = () => {
    dispatch(smsSlice.actions.handleTabSelected(1));
  };

  const handleCloseModal = () => {
    setModalSetting({
      isOpenModal: false,
      status: 'Pending',
    });
  };

  const handleCloseModalCredit = () => {
    setIsOpenModalCredit(false);
  };

  const handleOpenModal = (status: string) => () => {
    setModalSetting({
      isOpenModal: true,
      status,
    });
  };

  const handleToggle = (value: string, key: keyof typeof state) => () => {
    setStatus((prev) => ({ ...prev, [key]: value }));
    const data = [...state[key]];
    const index = data.findIndex((item) => item === value);
    if (index === -1) {
      updateSms({
        isActive: true,
        receiver: key as Receiver,
        trackingStatus: camelToSnakeCase(value),
      }).then((res) => {
        if ('data' in res && res.data && res.data.state === 1) {
          data.push(value);
          // check Số dư < tổng chi phí
          if (res.data.data.balanceAlert) {
            setIsOpenModalCredit(true);
          }
          if (apiError) {
            setApiError('');
          }
          dispatch(commonSlice.actions.handleToast(handleToastMutation(res)));
          setState({
            ...state,
            [key]: data,
          });
          return;
        }
        setApiError('');
        dispatch(commonSlice.actions.handleToast(handleToastMutation(res)));
        setState({
          ...state,
          [key]: data,
        });
      });
    } else {
      updateSms({
        isActive: false,
        receiver: key as Receiver,
        trackingStatus: camelToSnakeCase(value),
      }).then((res) => {
        if ('data' in res && res.data && res.data.state === 1) {
          if (apiError) {
            setApiError('');
          }
          dispatch(commonSlice.actions.handleToast(handleToastMutation(res)));
          data.splice(index, 1);
          setState({
            ...state,
            [key]: data,
          });
          return;
        }
        setApiError(getErrorFromAPI(res));
      });
    }
  };

  const handleActiveStatus = (value: string) => {
    const data = [...state[tabs[tabSelected].id as keyof typeof state]];
    const result = data.find((item) => item === value);
    return !!result;
  };

  const smsTemplate = statusConfig.map((item, index) => ({
    id: index,
    orderStatus: item.label,
    subject: `Send notification after shipment turns into ${item.label} status`,
    status: () => (
      <div className="center">
        <Switch
          disable={updateSmsStatus.isLoading || isLock}
          onClick={handleToggle(item.value, tabs[tabSelected].id)}
          isActive={handleActiveStatus(item.value)}
          loading={status[tabs[tabSelected].id] === item.value && updateSmsStatus.isLoading}
        />
      </div>
    ),
    action: (
      <div className="center">
        <Tooltip content="See SMS Template">
          <Button onClick={handleOpenModal(item.label)} icon={ViewMinor} />
        </Tooltip>
      </div>
    ),
  }));

  const tableData = smsTemplate.map(({ id, orderStatus, subject, status, action }, index) => (
    <IndexTable.Row id={String(id || 0)} key={id} position={index}>
      <IndexTable.Cell>{orderStatus}</IndexTable.Cell>
      <IndexTable.Cell>{subject}</IndexTable.Cell>
      <IndexTable.Cell>{status()}</IndexTable.Cell>
      <IndexTable.Cell>{action}</IndexTable.Cell>
    </IndexTable.Row>
  ));

  return (
    <OrderStatusStyled>
      <Card>
        <Tabs tabs={tabs} selected={tabSelected} onSelect={handleTabChange} fitted>
          {apiError && (
            <div className="mb-8">
              <ErrorBanner isVisible>{apiError}</ErrorBanner>
            </div>
          )}
          <CustomTable
            tableProps={{
              itemCount: smsTemplate.length,
              headings: [
                { title: 'Order status' },
                { title: 'Subject' },
                { title: 'Status', alignment: 'center' },
                { title: 'Action', alignment: 'center' },
              ],
              selectable: false,
            }}
            skeletonLines={20}
            isLoading={getSmsOrderStatus.isLoading}
          >
            {tableData}
          </CustomTable>
        </Tabs>
      </Card>
      <Modal
        open={modalSetting.isOpenModal}
        title={"SMS's content configuration"}
        onClose={handleCloseModal}
        secondaryActions={[{ content: 'Close', onAction: handleCloseModal }]}
      >
        <div className="pd-16">
          <Text as="h6" variant="bodySm">
            Status : <Badge tone="info">{modalSetting.status}</Badge>
          </Text>
          <div className="mt-16">
            <Text as="h6" variant="bodySm">
              {'{{!!store_name!!}}: Your package with {{!!courier!!}} is {{!!status!!}}! {{!!tracking_url!!}}'}
            </Text>
          </div>
        </div>
      </Modal>

      <Modal open={isOpenModalCredit} title="" titleHidden onClose={handleCloseModalCredit}>
        <div
          className="center"
          style={{
            padding: '1rem 4.375rem',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
          }}
        >
          <img src={`${process.env.REACT_APP_S3_ASSETS_BASE_URL}/images/sms.png`} alt="sms" />
          <div className="mt-16">
            <Text as="h6" variant="bodySm">
              To successfully send SMS about delivery notifications, you have to add credit into your account
            </Text>
          </div>

          <div className="mt-16">
            <Button onClick={navigateSMSCredit} variant="primary">
              Add credit
            </Button>
          </div>
        </div>
      </Modal>
    </OrderStatusStyled>
  );
};

export default SMSTemplates;
