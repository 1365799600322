import { Checkbox, InlineError, InlineGrid, Modal, Select, SkeletonBodyText, Text, TextField } from '@shopify/polaris';
import {
  cutoffTimeOptions,
  dayInWeek,
  defaultOrderCutoffAndProcessingTimes,
  getDayLabelByDay,
  orderDescriptionError,
  timezoneOptions,
} from '../../config';
import { StyleOrderModal, StyledOrderDescription } from './styled';
import { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import trackingPageSlice, { eddSelector } from '@/redux/features/trackingPage.slice';
import { useGetEDDSettingsQuery, useUpdateEddSettingsMutation } from '@/redux/api/api.caller';
import commonSlice from '@/redux/features/common.slice';
import { getErrorFromAPI, handleToastMutation } from '@/helpers/toast';
import { accountSelector, christmasBannerSelector } from '@/redux/features/plan.slice';
import { CustomCard, ErrorBanner } from '@/components';
import { EditMinor } from '@shopify/polaris-icons';
import { isLockFeature } from '@/helpers';
import { AccountPlan } from '@/constants/enum';

const OrderDescription = () => {
  const dispatch = useDispatch();
  const [apiError, setApiError] = useState<string>('');
  const eddState = useSelector(eddSelector);
  const plan = useSelector(accountSelector);
  const { formattedEndDate } = useSelector(christmasBannerSelector);
  const isActiveChristmasFeature = new Date() < new Date(formattedEndDate);
  const isLockedFeature =
    isLockFeature([AccountPlan.Starter, AccountPlan.Basic]) && !plan.isOldPaidUser && !isActiveChristmasFeature;

  const { data, isLoading } = useGetEDDSettingsQuery(undefined, { skip: isLockedFeature });
  const [updateEddSetting, updatedEddData] = useUpdateEddSettingsMutation();

  const orderDescriptionArr = useMemo(() => {
    if (data && data.data) {
      const { cutoffTime, timezone, processingTime, businessDays } = data.data;
      return [
        {
          title: 'Order cutoff time',
          value: `${cutoffTime}:00 - (GMT${timezone}) UTC`,
        },
        {
          title: 'Additional processing time',
          value: `${processingTime} additional business days`,
        },
        {
          title: 'Business days',
          value: businessDays.map((day) => getDayLabelByDay(day)).join(', '),
        },
      ];
    }
    return defaultOrderCutoffAndProcessingTimes;
  }, [data]);

  const handleChangeOptions = (selected: string | boolean, key: keyof typeof eddState.orderDescription) => {
    setApiError('');
    if (typeof selected === 'string') {
      dispatch(
        trackingPageSlice.actions.handleChangeEDD({
          ...eddState,
          orderDescription: {
            ...eddState.orderDescription,
            [key]:
              key === 'processingTime'
                ? { value: String(Math.abs(Number(selected))), error: Number(selected) >= 1000 }
                : selected,
          },
        }),
      );
    }
    if (typeof selected === 'boolean') {
      const updatedBusinessDay = selected
        ? [...eddState.orderDescription.businessDays.value, Number(key)].sort()
        : eddState.orderDescription.businessDays.value.filter((day) => day !== Number(key));
      dispatch(
        trackingPageSlice.actions.handleChangeEDD({
          ...eddState,
          orderDescription: {
            ...eddState.orderDescription,
            businessDays: {
              ...eddState.orderDescription.businessDays,
              value: updatedBusinessDay,
              error: updatedBusinessDay.length === 0,
            },
          },
        }),
      );
    }
  };

  const handleCloseModal = () => {
    setApiError('');
    dispatch(
      trackingPageSlice.actions.handleChangeEDD({
        ...eddState,
        orderDescription: { ...eddState.orderDescription, isModalActive: false },
      }),
    );
  };
  const handleEditOrder = () =>
    dispatch(
      trackingPageSlice.actions.handleChangeEDD({
        ...eddState,
        orderDescription: { ...eddState.orderDescription, isModalActive: true },
      }),
    );

  const handleSubmitForm = () => {
    updateEddSetting({
      cutoffTime: eddState.orderDescription.cutoffTime,
      timezone: eddState.orderDescription.timezone,
      processingTime: eddState.orderDescription.processingTime.value,
      businessDays: eddState.orderDescription.businessDays.value,
    }).then((data) => {
      if ('data' in data && data.data && data.data.state === 1) {
        if (apiError) {
          setApiError('');
        }
        dispatch(commonSlice.actions.handleToast(handleToastMutation(data)));
        dispatch(
          trackingPageSlice.actions.handleChangeEDD({
            ...eddState,
            orderDescription: { ...eddState.orderDescription, isModalActive: false },
          }),
        );
        return;
      }
      setApiError(getErrorFromAPI(data));
    });
  };

  return (
    <StyledOrderDescription>
      <CustomCard
        className="mt-16"
        title="Order cutoff and processing times"
        actionGroup={[
          {
            content: 'Edit',
            variant: 'plain',
            onAction: handleEditOrder,
            icon: EditMinor,
            disabled: isLockedFeature,
          },
        ]}
      >
        {isLoading ? (
          <SkeletonBodyText lines={7} />
        ) : (
          orderDescriptionArr.length > 0 &&
          orderDescriptionArr.map((desc) => (
            <div key={desc.title} className="order-item">
              <Text variant="headingSm" as="h6">
                {desc.title}
              </Text>
              <p>{desc.value}</p>
            </div>
          ))
        )}
      </CustomCard>

      <Modal
        open={eddState.orderDescription.isModalActive}
        onClose={handleCloseModal}
        title="Order cutoff and processing times"
        primaryAction={{
          content: 'Submit',
          onAction: handleSubmitForm,
          loading: updatedEddData.isLoading,
          disabled: eddState.orderDescription.processingTime.error || eddState.orderDescription.businessDays.error,
        }}
        secondaryActions={[
          {
            content: 'Cancel',
            onAction: handleCloseModal,
          },
        ]}
      >
        <StyleOrderModal>
          <Modal.Section>
            {apiError && (
              <div className="mb-8">
                <ErrorBanner isVisible>{apiError}</ErrorBanner>
              </div>
            )}
            <div className="order-section">
              <Text variant="headingSm" as="h6">
                Order cutoff time
              </Text>
              <p>Order cut-off time and estimated delivery time will be base on this timezone value</p>
              <InlineGrid columns={{ xs: 1, sm: 1, md: 1, lg: 2, xl: 2 }} gap="400">
                <Select
                  label="Time"
                  id="cutoffTime"
                  options={cutoffTimeOptions}
                  value={eddState.orderDescription.cutoffTime}
                  onChange={handleChangeOptions}
                />
                <Select
                  label="Timezone"
                  id="timezone"
                  options={timezoneOptions}
                  value={eddState.orderDescription.timezone}
                  onChange={handleChangeOptions}
                />
              </InlineGrid>
            </div>

            <div className="order-section">
              <Text variant="headingSm" as="h6">
                Order processing time
              </Text>
              <p>Select how long your warehouse needs to process an order</p>
              <TextField
                label=""
                type="number"
                id="processingTime"
                maxLength={3}
                max={999}
                autoComplete="off"
                value={JSON.stringify(Math.floor(Math.abs(Number(eddState.orderDescription.processingTime.value))))}
                onChange={handleChangeOptions}
                error={eddState.orderDescription.processingTime.error && orderDescriptionError.PROCESSING_TIME_ERROR}
                suffix="business days"
              />
            </div>

            <div className="order-section">
              <Text variant="headingSm" as="h6">
                Business days
              </Text>
              <p>
                A business day usually means any day except any Saturday, any Sunday, or any day which is a legal holiday or any
                day on which banking institutions are authorized or required by law or other governmental action to close.
              </p>
              <div className="business-days">
                {dayInWeek.map((item) => {
                  return (
                    <Checkbox
                      id={String(item.code)}
                      key={item.code}
                      label={item.label}
                      checked={eddState.orderDescription.businessDays.value.includes(item.code)}
                      onChange={handleChangeOptions}
                    />
                  );
                })}
              </div>
              {eddState.orderDescription.businessDays.error && (
                <InlineError message={orderDescriptionError.BUSINESS_DAYS_ERROR} fieldID="business-days-error" />
              )}
            </div>
          </Modal.Section>
        </StyleOrderModal>
      </Modal>
    </StyledOrderDescription>
  );
};

export default OrderDescription;
